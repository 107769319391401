import { StaticImage } from "gatsby-plugin-image";
import React from "react";

export const BuddhismCover = ( { lang } ) =>
    <StaticImage
        src={"../../../assets/images/buddhism-cover.jpg"}
        alt={ lang==="en" ? "Contexts and Dialogue: Yogācāra Buddhism and Modern Psychology on the Subliminal Mind" : "语境与对话：佛教唯识宗与当代心理学之潜意识论" }
        placeholder="blurred"
        imgStyle={{ objectFit: "contain" }}
        style={{ height: "100%", width: "100%" }}
    />